#flexContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    /* Default: side-by-side columns */
  }
  
  .box3   {
    /* border: 1px solid #ccc; */
    margin-right: 10px;
    flex-basis: 40vw;
    /* background-color: var(--body-color); */
  }

  h1, #bizviewhelp {
    color: var(--first-color);
  }

  h2,
  p {
  color: var(--second-color);
  text-align: center;
  }

  #certifications {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* margin-top: 70px; */
    /* Default: side-by-side columns */
  }

  #SpectoConsultHelp {
    margin-top: 1vw;
    text-align: center;
    color: var(--first-color);
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px;
    /* Optional: Add more styling for spacing, alignment, etc. */
  }

  .image {
    max-width: 30%;
    /* max-height: 50px; */
    height: 50px;
    margin-right: 20px;
  }

   /* Media query for mobile devices */
  @media (max-width: 1100px) {
    #flexContainer {
      flex-direction: column; /* Stacks columns vertically on mobile */
      width: 100%;
      justify-content: center;
      width: 100%;
      margin-top: 20px;
    }    
    .box3  {
      margin-bottom: 20px;
      width: 100%;
      flex-grow: 1;
      flex-basis: 400px;
    }
    #SpectoConsultHelp {
      margin-top: 1vw;
      text-align: center;
      color: var(--first-color);
      font-size: 5vw;
    }

  }
  