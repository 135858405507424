#flexContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.box2 {
  margin: 10px;
  flex-basis: 40vw;
  background-color: var(--body-color);
  padding: 10px;
}

h2, p {
  color: var(--second-color);
  text-align: center;
  margin: 10px 0;
}

p {
  overflow-wrap: break-word;
}

#certifications {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#SpectoConsultHelp {
  margin-top: 1vw;
  text-align: center;
  color: var(--first-color);
  font-size: var(--h1-font-size); /* Ensure base font size for larger screens */
}

.personalimage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
}

.personalimage {
  height: 15vw; /* Adjust height as needed */
  max-width: 100%; /* Ensure image doesn't exceed its container width */
  display: block;
  margin: auto; /* Center the image horizontally */
  cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
}

.linkedin-text {
  margin-top: 10px;
  font-size: 1rem;
  color: white; /* Text color is now white */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s; /* Smooth transition */
}

.linkedin-text a {
  color: white; /* Set link color to white */
  text-decoration: underline; /* Optional: underline the link text */
}

.personalimage-container:hover .linkedin-text,
.personalimage-container:focus-within .linkedin-text {
  opacity: 1; /* Show text on hover or focus */
}

.imagecert {
  max-width: 100%;
  height: auto;
  margin: 10px 0;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .personalimage {
    height: 200px; /* Adjust height for smaller screens */
  }
}
