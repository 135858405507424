/* CookiePolicy.css (or integrate into your existing CSS) */

#flexContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
    /* Default: side-by-side columns */
  }
  
  .box2 {
    /* border: 1px solid #ccc; */
    margin-right: 10px;
    flex-basis: 40vw;
    background-color: var(--body-color); /* Replace with your body color variable */
  }
  
  h2,
  p {
    color: var(--second-color); /* Replace with your secondary color variable */
    text-align: center;
  }
  
  #SpectoConsultHelp {
    margin-top: 1vw;
    text-align: center;
    color: var(--first-color); /* Replace with your primary color variable */
  }
  
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px;
    /* Optional: Add more styling for spacing, alignment, etc. */
  }
  
  .imagecert {
    max-width: 30%;
    /* max-height: 50px; */
    height: 80px;
    margin-right: 20px;
  }
  
  /* Media query for mobile devices */
  @media (max-width: 1100px) {
    #flexContainer {
      flex-direction: column; /* Stacks columns vertically on mobile */
      width: 100%;
      justify-content: center;
      width: 100%;
      margin-top: 20px;
    }
  
    .box2 {
      margin-bottom: 20px;
      width: 100%;
      flex-grow: 1;
      flex-basis: 400px;
    }
  
    #SpectoConsultHelp {
      margin-top: 1vw;
      text-align: center;
      color: var(--first-color); /* Replace with your primary color variable */
      font-size: 5vw; /* Adjust font size for mobile */
    }
  }
  