#flexContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}

/* Box styling */
.box {
  border: 1px solid var(--line-color);
  margin: 10px;
  padding: 20px;
  flex-basis: 40vw;
  background-color: var(--box-color);
}

h2, p {
  color: var(--title-color);
  text-align: center;
}

#SpectoConsultHelp1 {
  margin-top: 1vw;
  text-align: center;
  color: var(--first-color);
}

/* Row styling */
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px;
}

/* Image styling */
.image {
  max-width: 30%;
  height: 50px;
  margin-right: 20px;
}

/* Center specific images */
#aau, #GAMP5, #HPALM {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* Media query for mobile devices */
@media (max-width: 1100px) {
  #flexContainer {
    flex-direction: column; /* Stacks columns vertically on mobile */
    margin-top: 20px;
  }
  
  .box {
    margin: 10px 10px 20px 10px; /* Adds side margins to the boxes */
    width: calc(100% - 20px); /* Adjusts width to account for the side margins */
    flex-grow: 1;
    flex-basis: auto;
    justify-content: center;
  }

  #SpectoConsultHelp1 {
    margin-top: 1vw;
    text-align: center;
    color: var(--first-color);
    font-size: 5vw;
  }
}
