/* Contact.css */
.contact-form-container {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: var(--box-color);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contact-form-container h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    font-family: var(--second-font);
    font-size: var(--h1-font-size);
    color: var(--title-color);
}

.form-group {
    margin-bottom: 1rem;
}

.form-group label {
    display: block;
    font-weight: var(--font-semi-bold);
    margin-bottom: 0.5rem;
    color: var(--title-color);
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    background-color: var(--body-color);
    color: var(--text-color);
}

.form-group textarea {
    resize: vertical;
    height: 150px;
}

.btn {
    display: block;
    width: 100%;
    padding: 0.75rem;
    background-color: var(--first-color);
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    margin-top: 1rem;
}

.btn:hover {
    background-color: darken(var(--first-color), 10%);
}

.success-message {
    color: green;
    text-align: center;
    margin-bottom: 1rem;
}

.error-message {
    color: red;
    text-align: center;
    margin-bottom: 1rem;
}

h1 {
  color: var(--first-color);
  text-align: center;
  }