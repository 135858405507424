/* src/components/CookieConsent/CookieConsent.css */
.cookie-consent-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 10px;
    text-align: center;
    z-index: 1000;
  }
  
  .cookie-consent-banner p {
    margin: 0;
    padding: 0 10px;
  }
  
  .cookie-consent-banner button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .cookie-consent-banner button:hover {
    background-color: #45a049;
  }
  